import * as React from 'react';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import StarImg from '../../../images/Transmission/StarProduct.jpg';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import VideoModal from '../views/VideoModal';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import img from './../../../images/services.png'

import Breadcrumbs from './Breadcrumbs';

export default function Services() {
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])

    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };
    return (
        <>
            <Container sx={{ display: 'flex', position: 'relative', height: "100%" }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Grid container direction="column">
                            <Box>
                                <Breadcrumbs title={"Servicios"} />
                                <Typography variant="h5" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
                                    Servicios
                                </Typography>
                                <Typography
                                    align="flex-start"
                                    variant="subtitle1"
                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                                >
                                    Siemens Energy Service ofrece las soluciones de servicio adecuadas para su turbina de gas, turbina de vapor o generador para aumentar la flexibilidad, la eficiencia, la confiabilidad y la disponibilidad durante todo el ciclo de vida de sus activos.
                                </Typography>

                            </Box>
                            <Grid item align="center">
                                <img
                                    src={img}
                                    width={imgSize}
                                />
                            </Grid>
                            <Grid align={"center"}>
                                <Grid item>
                                    <Link rel="noopener noreferrer"
                                        target="_blank"
                                        href={"/pdfs/Services/Services.pdf"}
                                        underline="none"
                                    >
                                        <Button variant="contained"
                                            color="primary"
                                            size="large"
                                            startIcon={<PDFIcon />}
                                            sx={{
                                                borderRadius: 8,
                                                mb: 4, mt: { xs: 2, sx: 2, sm: 2 },
                                            }}
                                        >
                                            Descargar Folleto
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link rel="noopener noreferrer"
                                        target="_blank"
                                        href={"https://www.siemens-energy.com/mx/es/soluciones/servicios.html"}
                                        underline="none"
                                    >
                                        <Button variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{
                                                borderRadius: 8,
                                                mb: 4, mt: { xs: 2, sx: 2, sm: 2 },
                                            }}
                                        >
                                            Mas información
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

        </>
    );
}