import * as React from 'react';
import MenuCards from "./MenuCards";
import Header from './modules/views/Header';
import HeaderImage from './modules/views/HeaderImage';
import EventLink from './modules/views/EventLink';
import ROCInfo from './modules/views/ROCInfo';
import OfficeInfo from './modules/views/OfficeInfo';
import CorporateTabs from './modules/views/CorporateTabs'
import PaperTabs from './modules/views/PaperTabs'
import TurbineTabs from './modules/views/TurbineTabs'
import SolutionsTabs from './modules/views/SolutionsTabs'
import AnyQuestions from './modules/views/AnyQuestions'



function Index(props) {
  return (
    <React.Fragment>
      <HeaderImage />
      <Header />

      {props.loggedIn ?
        <EventLink/>
        : null

      }


      <MenuCards />

      <section id="presentations">
        <CorporateTabs />
      </section>

      <section id="papers">
        <PaperTabs />
      </section>

      <section id="office">
        <OfficeInfo />
      </section>

      {/* <section id="end">
        <AnyQuestions />
      </section> */}
    </React.Fragment>
  );
}

export default Index;
