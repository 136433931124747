import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  

export default function MyBreadcrumbs(props) {
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" sx={{ display: 'flex', alignItems: 'center' }} href="/" onClick={() => { navigate("/") }}>
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={handleClick}
        >
            {props.title}
        </Link>
    ];

    const navigate = useNavigate();
   

    return (<Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 } }}
        spacing={2}
    >
        {breadcrumbs}
    </Breadcrumbs>);

}