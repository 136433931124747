import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';




export default function AnyQuestion() {
    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };

    return (
        <Container sx={{ display: 'flex', position: 'relative' }}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h5" sx={{ mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                            {"¡Conocé todo lo que está pasando en la semana de AOG Patagonia 2022!"}
                        </Typography>
                        <Typography
                            align="flex-start"
                            variant="subtitle1"
                            sx={{ mt: { xs: 4, sx: 3, sm: 3 }, color: 'text.main' }}
                        >
                            Te compartimos la agenda de Siemens Energy en el evento Argentina Oil & Gas Patagonia 2022. Enterate cuándo y dónde vamos a estar.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item >
                    <Box >
                        <NavLink
                            to="/evento"
                            underline="none"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button variant="contained"
                                color="primary"
                                size="large"
                                sx={{
                                    borderRadius: 8,
                                    mb: 4,
                                }}
                            >
                                Más información
                            </Button>
                        </NavLink>
                    </Box>
                </Grid>
            </Grid>
        </Container >
    )
}