import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';

import InterImg from '../../../images/Transmission/interruptores.png';
import SeccionImg from '../../../images/Transmission/seccionadores.jpg';
import TransformImg from '../../../images/Transmission/sensformer.jpg';
import DescarImg from '../../../images/Transmission/surgearrestors.jpg';
import TrenchImg from '../../../images/Transmission/trench.png';

import PlaceHolder from '../../../images/placeholder.png';

import VideoModal from '../views/VideoModal';

import { useNavigate } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const products = [
    {
        button: "Descargar Folleto Interruptores",
        title: "Interruptores",
        image: InterImg,
        pdfLink: "/pdfs/Transmission/InterruptoresBrochure.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-transmission/portfolio/circuit-breakers.html",
        description: "Aislados en aire, el portafolio más completo con interrupción en SF6, de vacío y aire limpio. Para todas las aplicaciones de alta tensión, hasta 1.200kV"
    },
    {
        button: "Descargar Folleto Seccionadores",
        title: "Seccionadores",
        image: SeccionImg,
        pdfLink: "/pdfs/Transmission/SeccionadoresCatalog.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-transmission/portfolio/disconnectors-earthing-switches.html",
        description: "Rango de voltaje de 36 kV a 800 kV."

    },
    {
        button: "Descargar Folleto",
        title: "Transformadores de Potencia y Reactores",
        image: TransformImg,
        pdfLink: "/pdfs/Transmission/TransformadoresDePotencia.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-transmission/portfolio/transformers.html",
        description: "Portafolio completo y personalizado para satisfacer las necesidades específicas de    nuestros clientes, con clasificaciones hasta 1.200 kV. Tecnología desarrollada en fluidos de éster como alternativa biodegradable para el aislamiento de transformadores."

    },
    {
        button: "Descargar Folleto Descargadores",
        title: "Descargadores",
        image: DescarImg,
        pdfLink: "/pdfs/Transmission/DescargadoresBrochure.pdf",
        description: "Caracterizados por una fácil instalación, bajos costos de mantenimiento y una vida útil extremadamente larga. Aplicaciones desde 13,2kV en adelante, estándar, especiales y de líneas aéreas compactas."

    },
    {
        button: "Descargar Folleto",
        title: "Transformadores de Medición - TRENCH",
        image: TrenchImg,
        pdfLink: "/pdfs/Transmission/TransformadoresTRENCH.pdf",
        description: "Mayor precisión en la medición de señales de tensión o corriente, configurable según la necesidad del cliente. Soluciones medioambientales libres de SF6 de hasta 420kV. "

    },

]




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const navigate = useNavigate();


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box component="section"
                width="100%"
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'hidden',
                    mt: 5,
                    mb: 5,
                }}
            >

                <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 2 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} color="white">
                                <Tabs value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    xs={12}
                                    sx={{ selected: { backgroundColor: "secondary" } }}
                                >

                                    <Tab label="Interruptores" {...a11yProps(0)} />
                                    <Tab label="Seccionadores" {...a11yProps(1)} />
                                    <Tab label="Transformadores de potencia y reactores" {...a11yProps(2)} />
                                    <Tab label="Descargadores" {...a11yProps(3)} />
                                    <Tab label="Transformadores de medición" {...a11yProps(4)} />
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                products.map((product, idx) => {
                                    return (
                                        <TabPanel value={value} index={idx}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={5} align="center">

                                                    <img
                                                        width={imgSize}
                                                        src={product.image}
                                                    />

                                                </Grid>
                                                <Grid item xs={12} lg={7}>
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item >
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                                                {product.title}
                                                            </Typography>
                                                            <br />
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{ mb: 4, color: 'white' }}>
                                                                {product.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" justifyContent="space-around" spacing={2}>
                                                                <Grid item>
                                                                    <Link rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        href={product.pdfLink}
                                                                        underline="none"
                                                                        color="white"
                                                                    >
                                                                        <Button variant="outlined" color="inherit" startIcon={<PDFIcon />} sx={{ borderRadius: 8 }}>{product.button}</Button>
                                                                    </Link>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Link rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        href={product.link}
                                                                        underline="none"
                                                                        color="white"
                                                                    >
                                                                        <Button variant="outlined" color="inherit" sx={{ borderRadius: 8 }}>Mas Información</Button>
                                                                    </Link>
                                                                </Grid>
                                                                {product.videoLink ?

                                                                    <Grid item>
                                                                        <VideoModal title={"Ver Video"} color={"white"} variant={"outlined"} link={product.videoLink} />
                                                                    </Grid>

                                                                    : null
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box >
        </>
    );
}