import * as React from 'react';
//import Typography from '../components/Typography';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Menu from './menu';

import VideoModal from './VideoModal.js';

import Image from '../../../images/expobg.png'

export default function Header() {
  return (
    <>
      <Box component="section"
        width="100%"
        sx={{
          flexDirection: 'column',
          display: 'flex',
          overflow: 'hidden',
          mt: 5,
          mb: 1,
        }}
      >

        <Container sx={{ display: 'flex', position: 'relative' }}>
          <Grid container spacing={5}>
            {/* <Grid item align="center">
              <Menu />
            </Grid> */}
            <Grid item xs={12}>
              <Box >
                <Typography variant="h4" sx={{ mt: { xs: 5, sx: 4, sm: 5, fontWeight: 'bold', color: '#1B1534' } }}>
                  Bienvenidos a la experiencia Siemens Energy
                </Typography>
                <Typography
                  align="flex-start"
                  variant="subtitle1"
                  sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                >
                  Explora nuestra aplicación para encontrar detalles e información de cada uno de nuestros productos, soluciones y servicios afines a las necesidades de la industria del petróleo y el gas. Te esperamos en nuestro stand 1D30, ubicado en el hall 1, del evento “Argentina Oíl & Gas Patagonia”. Allí estarán nuestros profesionales expertos de Siemens Energy presentando los productos que encontraras aquí y podrán responder todas tus preguntas e inquietudes. </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}