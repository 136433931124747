import * as React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

export default function Schedule() {
    return (
        <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid item>
                <Typography align="flex-start" variant="h5">
                    Agenda
                </Typography>
            </Grid>
            <Card spacing={2} sx={{ width: "100%", bgcolor: 'primary.dark' }}>
                <CardContent>
                    <Box sx={{ width: '100%',  minHeight: "320px", bgcolor: 'background.paper' }}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText primary="Miércoles 10 de Agosto" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ pl: 6 }} component="a">
                                    <ListItemText primary={`15:00 - Expo AOG 2022 Patagonia: "Conferencia Combustión, emisiones y eficiencia turbomáquinas." - Sala 2`} />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText primary="Jueves 11 de Agosto" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ pl: 6 }} component="a">
                                    <ListItemText primary={`15:00 - Expo AOG 2022 Patagonia: Conferencia "Operación Remota y Consultoría a Operadores" - Sala 2`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ pl: 6 }} component="a">
                                    <ListItemText primary={`18:30 - Inaguración de oficina Siemens Energy en Neuquén -  Oficina Siemens Energy (Hipólito Yrigoyen 579, Neuquén)`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );

}
