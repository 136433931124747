
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";


// material ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

import Dashboard from '../components/dashboard/Dashboard';
import AdminPanel from '../components/adminpanel';


export default function AdminPortal() {

  const [results, setResults] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [verifCode, setVerifCode] = useState('');
  const [error, setError] = useState("");
  const navigate = useNavigate();

  function getSurveyResults(id) {

    axios.get(`https://se-conference-app.herokuapp.com/results/export/?surveyId=${id}`)
      .then(function (response) {

        setResults(response.data);

        console.log(response.data);

      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        console.log(error);
      });

  }

  useEffect(() => {
    // const email = localStorage.getItem("email");

    // if (!email) {
    //   navigate("/");
    // }
    // setEmail(email);

    getSurveyResults(1);
  }, [])

  function handleSubmit() {
    //verifyAdmin();
    if (verifCode === "adminpass")
      setLoggedIn(true);
  }

  // function verifyAdmin() {
  //   axios.post(`https://se-conference-app.herokuapp.com/users/verifyAdmin`, {
  //     verification_code: verifCode
  //   })
  //     .then(function (response) {
  //       // If we get a "OK" status then we have successfully added a preset
  //       console.log("response in verifyAdmin:", response);

  //       if (response.data.admin)
  //         setLoggedIn(true);
  //       else {
  //         setError(response.data.err);
  //       }

  //     })
  //     // Handle an error response from the server (we had issuses creating our preset)
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  // CHANGE IT BACK WHEN
  return (<>
    {loggedIn ?
      <AdminPanel results={results} />
      :
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Please Sign in with Admin Password
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Admin Password"
                type="Admin Password"
                value={verifCode}
                onChange={e => setVerifCode(e.target.value)}
              />
              {
                error ? <Typography> {error} </Typography> :
                  null
              }
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Verify
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    }
  </>
  );
}