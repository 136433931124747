
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';

import DecarbIMG from '../../../images/Corporate/decarbonization.png'
import FutureTechIMG from '../../../images/Corporate/futuretech.png'
import MindsetIMG from '../../../images/Corporate/mindset.png'
import OfferingIMG from '../../../images/Corporate/offerings.png'
import DigiIMG from '../../../images/Corporate/digi.png'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const papers = [
    {
        title: "Descarbonización",
        image: DecarbIMG,
        pdfLink: "/pdfs/Corporate/Decarbonization-Journey.pdf",
        videoLink: "",
        description: "¿Cómo podemos implementar con éxito un trayecto de descarbonización que beneficie a nuestros clientes, a las sociedades en general y a nosotros mismos?"
    },
    {
        title: "Digitalización",
        image: DigiIMG,
        pdfLink: "/pdfs/Corporate/Digitalization.pdf",
        videoLink: "",
        description: "Con su exclusivo portafolio de digitalización, Siemens Energy está preparada para unir fuerzas con sus clientes y socios para impulsar la transformación digital del sector energético."

    },
    {
        title: "Tecnologías del Futuro",
        image: FutureTechIMG,
        pdfLink: "/pdfs/Corporate/Future-Technologies.pdf",
        videoLink: "",
        description: "Debemos estar preparados para cambiar de rumbo de forma rápida y decisiva para lograr las ambiciosas mitigaciones del cambio climático que el planeta necesita."

    },
    {
        title: "Mentalidad",
        image: MindsetIMG,
        pdfLink: "/pdfs/Corporate/Mindset.pdf",
        videoLink: "",
        description: "Cumplimos nuestras promesas y trabajamos como el proveedor preferido para desarrollar soluciones innovadoras para el futuro. Este es nuestro ADN. Al reunir a las personas adecuadas desbloqueamos un nuevo potencial."

    },
    {
        title: "Portafolio de Ofertas",
        image: OfferingIMG,
        pdfLink: "/pdfs/Corporate/Offerings-Portfolio.pdf",
        videoLink: "",
        description: "En los próximos años, queremos simplificar nuestros procesos de trabajo y producción, mejorar la selección y ejecución de proyectos y reducir nuestros inventarios."

    },
]





function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };

    return (
        <>
            <Container sx={{ display: 'flex', position: 'relative' }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box >
                            <Typography variant="h5" sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                                Temas de Interés
                            </Typography>
                            <Typography
                                align="flex-start"
                                variant="subtitle1"
                                sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                            >
                                Siemens Energy está lista para convertirse en un referente en el sector. No va a suceder de la noche a la mañana. Sin embargo, estamos decididos a cumplir nuestro propósito y alcanzar nuestras metas. Nuestra cartera única y nuestro enfoque inquebrantable en el valor para el cliente tendrán el mayor impacto en cambiar el mundo de la energía.                           </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box component="section"
                width="100%"
                alignItems={"center"}
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'hidden',
                    mt: 5,
                    mb: 5,
                }}
            >
                <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 2 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} color="white">
                                <Tabs value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    xs={12}
                                    sx={{ selected: { backgroundColor: "secondary" } }}
                                >

                                    <Tab label="Descarbonización" {...a11yProps(0)} />
                                    <Tab label="Digitalización" {...a11yProps(1)} />
                                    <Tab label="Tecnologías del Futuro" {...a11yProps(2)} />
                                    <Tab label="Mentalidad" {...a11yProps(3)} />
                                    <Tab label="Portafolio de Ofertas" {...a11yProps(4)} />

                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                papers.map((content, idx) => {
                                    return (
                                        <TabPanel value={value} index={idx}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={5} align="center">
                                                    <Link href={content.image} target="_blank">
                                                        <img
                                                            width={imgSize}
                                                            src={content.image}
                                                        />
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={12} lg={7}>
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item >
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                                                {content.title}
                                                            </Typography>
                                                            <br />
                                                            <Typography
                                                                sx={{ mb: 4, color: 'white' }}>
                                                                {content.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" justifyContent="space-around" spacing={2}>
                                                                <Grid item >
                                                                    <Link rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        href={content.pdfLink}
                                                                        underline="none"
                                                                        color="white"
                                                                    >
                                                                        <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto</Button>
                                                                    </Link>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box >
        </>

    );
}