import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

// material ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';

import img from '../images/verify.png';



export default function Login(props) {
  const [email, setEmail] = useState('');
  const [verifCode, setVerifCode] = useState('');
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();


  useEffect(() => {
    const email = localStorage.getItem("loggedIn");
    if (email) {
      navigate("/");
    }
  }, [])


  function handleSubmit() {
    verifyUser();
  }

  function verifyUser() {

    axios.post(`https://se-conference-app.herokuapp.com/users/login`, {
      verification_code: verifCode
    })
      .then(function (response) {
        // If we get a "OK" status then we have successfully added a preset
        console.log("response in verifyUser:", response);

        if (!response.data.correctCode) {
          // send invalid code error
          setMessage("Invalid code");
        }

        else {
          // nav to rest of app
          localStorage.setItem("loggedIn", true);
          props.setLoggedIn(true);
          navigate("/");
        }

      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        setMessage("Something bad happened")
        console.log(error);
      });

  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Por favor, ingrese código de verificación
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Código de Verificación"
              type="verificationCode"
              value={verifCode}
              onChange={e => setVerifCode(e.target.value)}
            />
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 8 }}
            >
              Ingresar
            </Button>
            <Typography>{message}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>);
}