import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import ROCImg from '../../../images/roc.png';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import VideoModal from './VideoModal.js';

import Breadcrumbs from './Breadcrumbs';

export default function ROCInfo() {
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])

    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };
    return (
        <>

            <Container sx={{ display: 'flex', position: 'relative' }}>

                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box>
                            <Breadcrumbs title={"Operación Remota y Consultoría"} />
                            <Typography variant="h5" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
                                Operación Remota y Consultoría
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5, color: 'text.main' } }}>
                                La Operación Remota y Consultoría puede ayudar a sus operadores a operar varios sitios con el apoyo y experiencia de Siemens Energy.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box component="section"
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'hidden',
                    mt: 5,
                    mb: 5,
                }}
            >
                <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 5 }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5} align="center">

                            <img
                                width={imgSize}
                                src={ROCImg}
                            />

                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="space-between">
                                <Grid item >
                                    <Typography
                                        variant="h6"
                                        sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                        Operación Remota y Consultoría
                                    </Typography>
                                    <br />
                                    <Typography
                                        sx={{ mb: 4, color: 'white' }}>
                                        La Operación Remota y Consultoría de Siemens Energy lo ayuda a iniciar y configurar su propio Centro de Operación Remota (ROC) para operar todos sus activos incluyendo diferentes tecnologías y marcas de equipos; tanto para generación de energía como para otras industrias; por ejemplo, Petróleo y Gas, Refinerías, Petroquímicas, Papeleras, Minería, Marina, Alimenticia, entre otras.                                </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Link rel="noopener noreferrer"
                                                target="_blank"
                                                href="/pdfs/Services/ROCBrochure.pdf"
                                                underline="none"
                                                color="white"
                                            >
                                                <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto</Button>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <VideoModal title={"Ver Video"} color={"white"} variant={"outlined"} link={"https://sepatagonia.ddns.net/videos/ROCVideo.mp4"} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}