import * as React from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';


import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

export default function Numbers() {
    return (
        <Grid container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid item>
                <Typography align="flex-start" variant="h5">
                    Transportes
                </Typography>
            </Grid>
            <Card spacing={2} sx={{ bgcolor: 'primary.dark' }}>
                <CardContent>
                    <Box sx={{ bgcolor: 'background.paper' }}>
                        <Grid container direction="row">
                            {/* <Grid item xs={6}>
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton>
                                            <ListItemText primary="Remisport" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton component="a">
                                            <ListItemText primary={`Remises del Alto`} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton component="a">
                                            <ListItemText primary={`Taxis Ceferino`} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton component="a">
                                            <ListItemText primary={`Taxi Gran Neuquén`} />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Grid> */}
                            <Box xs={12}>
                                <List spacing={2}>
                                    <Grid alignItems="center" spacing={2}>
                                        <ListItem inset disablePadding>
                                            <ListItemButton component="a">
                                                <ListItemIcon>
                                                    <PhoneIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Remisport" />

                                                <ListItemText primary={`+54 299 442 8314`} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem inset disablePadding>
                                            <ListItemButton component="a">
                                                <ListItemIcon>
                                                    <PhoneIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={`Remises del Alto`} />

                                                <ListItemText primary={` +54 299 443 9484`} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem spacing={2} disablePadding>
                                            <ListItemButton component="a">
                                                <ListItemIcon>
                                                    <PhoneIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={`Taxis Ceferino`} />
                                                
                                                <ListItemText primary={`   +54 299 440 1140 / 1100/ 1230`} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem inset disablePadding>
                                            <ListItemButton component="a">
                                                <ListItemIcon>
                                                    <PhoneIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={`Taxi Gran Neuquén`} />

                                                <ListItemText primary={`+54 299 446 1053`} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Grid>
                                </List>
                            </Box>
                        </Grid>
                    </Box>
                </CardContent>
            </Card >
            <Typography sx={{mt:3}}>
                Valor aproximado desde aeropuerto a Hotel Comahue: $1500.
                Se pueden pagar con tarjetas.

            </Typography>
        </Grid >
    );

}
