import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import StarImg from '../../../images/Transmission/StarProduct.jpg';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import VideoModal from '../views/VideoModal';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";




export default function StarProduct() {
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const navigate = useNavigate();


    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }


    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" sx={{ display: 'flex', alignItems: 'center' }} href="/" onClick={() => { navigate("/") }}>
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={handleClick}
        >
            Transmisión
        </Link>
    ];


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])

    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };
    return (
        <>
            <Container sx={{ display: 'flex', position: 'relative' }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                                sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 } }}
                                spacing={2}
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                            <Typography variant="h5" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
                                Transmisión
                            </Typography>

                            <Typography
                                align="flex-start"
                                variant="subtitle1"
                                sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                            >
                                Varios factores dominarán el mercado de transmisión de energía en los próximos años: el impulso hacia las energías renovables, la expansión e interconexión de las infraestructuras de red y la necesidad de reemplazar y actualizar gradualmente las infraestructuras de red más antiguas. Descubra cómo nuestro amplio portafolio de productos y soluciones innovadores para la transmisión de energía respalda estas transformaciones.                            </Typography>
                        </Box>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Link rel="noopener noreferrer"
                                    target="_blank"
                                    href={"/pdfs/Transmission/TransmissionPresentation.pdf"}
                                    underline="none"
                                >
                                    <Button variant="contained"
                                        color="primary"

                                        startIcon={<PDFIcon />}
                                        sx={{
                                            borderRadius: 8,

                                        }}
                                    >
                                        Descargar Folleto
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item>
                                <VideoModal color={"primary"} variant={"contained"} title={"Ver Video"} link={"https://sepatagonia.ddns.net/videos/ProductosTransmissionTrailer.mp4"} />
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
            </Container>
            <Box component="section"
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'hidden',
                    mt: 5,
                    mb: 5,
                }}
            >
                <Container sx={{ display: 'flex', position: 'relative' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} >
                            <Box>
                                <Typography variant="h5" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
                                    Productos
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 5 }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5} align="center">

                            <img
                                width={imgSize}
                                src={StarImg}
                            />

                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Grid container justifyContent="space-between">
                                <Grid item >
                                    <Typography
                                        variant="h6"
                                        sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                        Subestación Encapsulada en Gas
                                    </Typography>
                                    <br />
                                    <Typography
                                        sx={{ mb: 4, color: 'white' }}>
                                        Solución compacta para instalación en interiores y exteriores, desde 33 hastas 550kV, incluso en áreas ambientalmente sensibles. Tasas de falla más bajas y vida útil confiable del producto de más de 50 años, con tecnología probada para aire limpio e interrupción en vacío.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Link rel="noopener noreferrer"
                                                target="_blank"
                                                href="/pdfs/Transmission/8DN8.pdf"
                                                underline="none"
                                                color="white"
                                            >
                                                <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto 8DN8</Button>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link rel="noopener noreferrer"
                                                target="_blank"
                                                href="/pdfs/Transmission/8DQ1.pdf"
                                                underline="none"
                                                color="white"
                                            >
                                                <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto 8DQ1</Button>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link rel="noopener noreferrer"
                                                target="_blank"
                                                href="/pdfs/Transmission/8VN1BlueGIS.pdf"
                                                underline="none"
                                                color="white"
                                            >
                                                <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto 8VN1 Blue GIS </Button>
                                            </Link>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}