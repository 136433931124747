import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

// material ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const { getNames } = require('country-list');

const countries = getNames();

export default function Register() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');

  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      navigate("/welcome");
    }
  }, [])


  function handleSubmit() {
    registerUser();
    setMessage("Submitted");
  }

  function registerUser() {

    axios.post(`https://se-conference-app.herokuapp.com/users/register`, {
      email: email,
      name: name,
      country: country
    })
      .then(function (response) {

        console.log("response in registerUser:", response);

        if (!response.data.invited) {
          // send invalid code error
          setMessage("Invalid email");
        }

        else {
          localStorage.setItem("email", email);
          navigate("/welcome");
        }

      })
      // Handle an error response from the server (we had issuses creating our preset)
      .catch(function (error) {
        console.log(error);
      });
  }


  function handleCountry(event) {
    setCountry(event.target.value);
  }


  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <Box component="form" sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Name"
              autoFocus
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Email Address"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                label="Country"
                required
                onChange={handleCountry}
              >
                {countries ? countries.map((country) => {

                  return <MenuItem value={country}>{country}</MenuItem>

                }) : null
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
        <Grid container justifyContent="flex-end">
        </Grid>
        <Typography>{message}</Typography>
      </Box>
    </Box>
  );

}