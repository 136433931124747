import React, { useState, useEffect } from 'react';
import AnnouncementCard from './announcementCard';
import axios from "axios";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export default function NewAnnouncement(props) {
    const [title, setTitle] = useState(null);
    const [desc, setDesc] = useState(null);

    function handleSubmit() {
        postAnnouncement();
      //  props.setRefresh(true);
    }

    function postAnnouncement() {
        axios.post(`https://se-conference-app.herokuapp.com/announcements/create`, {
            title: title,
            desc: desc
        })
            .then(function (response) {
            })
            // Handle an error response from the server (we had issuses creating our preset)
            .catch(function (error) {
            });
    }

    return (
        <>
            <Box xs={6} content="section">
                <Card sx={{ borderColor: 'secondary.main' }}>
                    <CardContent>
                        <CardActions>
                            <Grid container direction="column" alignItems="center" spacing={2}>
                                <Grid item >
                                    <TextField
                                        placeholder="Title"
                                        style={{ minWidth: 400 }}
                                        onChange={(e) => { setTitle(e.target.value) }}
                                    />
                                </Grid>
                                <Grid item >
                                    <TextField
                                        placeholder="Description"
                                        multiline
                                        style={{ minWidth: 400 }}
                                        onChange={(e) => { setDesc(e.target.value) }}
                                    />
                                </Grid>
                                {/* <Grid item >
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item >
                                            <TextField
                                                placeholder="Link Display"
                                                multiline
                                                style={{ minWidth: 200 }}
                                                onChange={(e) => { setDesc(e.target.value) }}
                                            />
                                        </Grid>
                                        <Grid item >
                                            <TextField
                                                placeholder="Link URL"
                                                multiline
                                                style={{ minWidth: 200 }}
                                                onChange={(e) => { setDesc(e.target.value) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item >
                                    <Button
                                        onClick={handleSubmit}
                                        variant="contained"
                                        sx={{ borderRadius: 8 }}>
                                        Post Announcement
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
}