import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './sidebar';
import SELogo from '../images/logo.svg';

export default function NavBar(props) {

  // const [loggedIn, setLoggedIn] = useState(false); 

  // useEffect(() => {

  //   const loggedIn = localStorage.getItem("loggedIn");
  //   if (loggedIn) {
  //     setLoggedIn(true); 
  //   }

  // }, []); 

  // There are two Toolbars because the fixed AppBar covers the top of the webpage. 

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} position="fixed" sx={{bgcolor: "#1B1534"}}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <img src={SELogo} alt="Siemens Energy" />
          </Box>

          <Sidebar loggedIn={props.loggedIn} />

        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <img src={SELogo} alt="Siemens Energy" />
        </Box>
      </Toolbar>
    </Box >
  );
}

