import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from '@mui/material/IconButton';
import PlaceHolder from '../images/placeholder.png'


import ServicesImg from '../images/ServicesMenu.jpg';
import NewUnitsImg from '../images/NewUnitsMenu.png';
import TransmissionImg from '../images/TransmissionMenu.png';
import SolutionsImg from '../images/SolutionsMenu.png';
import ROCImg from '../images/ROCMenu.png';

const links = [
    {
        name: "Servicios",
        image: ServicesImg,
        link: "/services"
    },
    {
        name: "Operación Remota y Consultoría",
        image: ROCImg,
        link: "/roc"
    },
    {
        name: "Nuevas Unidades",
        image: NewUnitsImg,
        link: "/newunits"
    },
    {
        name: "Transmisión",
        image: TransmissionImg,
        link: "/transmission"
    },
    {
        name: "Soluciones",
        image: SolutionsImg,
        link: "/solutions"
    },
]

export default function Album() {
    return (
        <Container sx={{ display: 'flex', position: 'relative', mt: 5 }}>
            <Grid container spacing={2}>
                {links.map((link) => (
                    <Grid item xs={12} sm={6} md={4}>
                        <Card
                            sx={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}
                        >
                            <CardMedia
                                component="img"
                                height="200"
                                image={link.image}
                                alt={`${link.name} image`}
                            />
                            <CardActions>
                                <Box sx={{ width: "100%" }}>
                                    <Link
                                        href={link.link}
                                        underline="none"
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                        >
                                            <Grid item>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {link.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="flex-end">
                                            <Grid item>

                                                <IconButton aria-label="delete"  color="primary">
                                                    <NavigateNextIcon sx={{fontSize: "40px"}} />
                                                </IconButton>

                                            </Grid>
                                        </Grid>
                                    </Link>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>
                ))
                }
            </Grid >
        </Container >

    );
}