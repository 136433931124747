import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { NavLink } from "react-router-dom";
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';




export default function AnyQuestion() {
    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };

    return (
        <Container sx={{ display: 'flex', position: 'relative' }}>
            <Grid container spacing={5}>

                {
                    (useLocation().pathname !== "/contacts") ?
                        <>

                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h5" sx={{ mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                                        ¿Cómo podemos ayudarle?
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item >
                                <Box >
                                    <NavLink
                                        to="/contacts"
                                        underline="none"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Button variant="contained"
                                            color="primary"
                                            // size="large"
                                            sx={{
                                                borderRadius: 8,
                                                mb: 4, mt: { xs: 2, sx: 2, sm: 2 },
                                                textTransform: "none",
                                            }}
                                        >
                                            Si tiene preguntas, comentarios o sugerencias, comuníquese con nosotros.
                                        </Button>
                                    </NavLink>
                                </Box>
                                <Typography>
                                    { }
                                </Typography>
                            </Grid>
                        </>
                        : null
                }


            </Grid>
        </Container>
    )
}