import * as React from 'react';

import StarProduct from './modules/TransmissionItems/StarProduct'; 
import ProductsTabs from './modules/TransmissionItems/ProductsTabs.js'; 
import TSolutionsTabs from './modules/TransmissionItems/TSolutionsTabs'; 

function Index() {
  return (
    
    <React.Fragment>
        <StarProduct/>
        <ProductsTabs/>
        <TSolutionsTabs/>
    </React.Fragment>
  );
}

export default Index;
