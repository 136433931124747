import { CSVLink, CSVDownload } from "react-csv";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Announcements from './announcements';

export default function AdminPanel(props) {
    return (
        <Grid container
            sx={{
                flexDirection: 'row',
                display: 'flex',
                overflow: 'hidden',
                mt: 5,
                mb: 5,
            }}>
            <Grid item xs={12}>
                <Announcements admin={true} />
            </Grid>
            {/* <Grid item xs={6}>
                {
                    props.results ? <CSVLink
                        data={props.results}
                        style={{ textDecoration: 'none' }
                        }
                        filename={"surveyresults"}
                    >
                        <Button
                            variant="contained"
                            sx={{ borderRadius: 8, mt: 3, mb: 2 }}
                        >
                            Export Survey Results as CSV
                        </Button>
                    </CSVLink>
                        : null
                }
            </Grid> */}
        </Grid >
    );
}