import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Schedule from '../components/schedule'
import Numbers from '../components/numbers'
import Announcements from '../components/announcements'
import Breadcrumbs from '../onepirate/modules/views/Breadcrumbs'
import useMediaQuery from '@mui/material/useMediaQuery';

import Plano from '../images/plano.jpg'


export default function EventInfo() {

    const [imgSize, setImgSize] = useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const navigate = useNavigate();


    useEffect(() => {
        const email = localStorage.getItem("loggedIn");
        if (!email) {
            navigate("/verify");
        }
    }, [])

    useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("600")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])



    return (
        <>
            <Container sx={{ display: 'flex', position: 'relative', height: "100%" }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box >
                            <Breadcrumbs title={"Agenda"} />
                            <Typography variant="h5" sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                                Evento Exclusivo
                            </Typography>
                            <Typography
                                align="flex-start"
                                variant="subtitle1"
                                sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                            >
                                Los invitamos a conocer las tendencias, desafíos y oportunidades que enfrenta el sector energético en su viaje de evolución y transformación hacia sistemas energéticos descarbonizados.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ display: 'flex', position: 'relative', height: "100%" }}>
                <Grid container direction="column" spacing={5}>
                    <Grid item>
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid item xs={12} md={7} sx={{mb:5}}>
                                <Schedule />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Announcements admin={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item align="center">
                        <Typography variant="h5">
                            Plano Evento AOG
                        </Typography>
                        <Link
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.aogpatagonia.com.ar/plano.pdf">
                            <img
                                src={Plano}
                                width={imgSize}
                            />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Numbers />
                    </Grid>
                </Grid>
            </Container>
        </>
    );

}