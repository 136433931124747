import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import BrochureIMG from '../../../images/brochureprev.png';

import Map from './Map';


export default function OfficeInfo() {
    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };

    const ref = React.useRef(null);
    const [map, setMap] = React.useState();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);




    return (
        <>
            <Box
                component="section"
                sx={{ flexDirection: 'column', display: 'flex', overflow: 'hidden', }}

            >
                <Container sx={{ display: 'flex', position: 'relative' }}>
                    <Grid container
                        spacing={5}
                        direction="column"
                        justifyContent="center"
                        alignItems="center" >
                        <Grid item xs={12}>
                            <Box >
                                <Typography variant="h5" /*align="center" */ sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 10, color: 'text.main' } }}>
                                Ubicación oficina de Siemens Energy en Neuquén.
                                </Typography>
                                <Typography
                                    /*align="center" */
                                    variant="subtitle1"
                                    sx={{ mb: 4, mt: 5, color: 'text.main' }}
                                >
                                    La oficina se encuentra en pleno centro de la ciudad de Neuquén, a 7 kilómetros del Aeropuerto Internacional y a 150 metros de la avenida principal "Avenida Argentina".
                                </Typography>
                            </Box>
                            <Grid item align="center">
                                <Map />
                            </Grid>
                            <Grid item align="center">
                                <Typography
                                    align="center"
                                    variant="subtitle1"
                                    sx={{ mb: 4, mt: 5, color: 'text.main' }}
                                >
                                    Hipólito Yrigoyen 579, Neuquén, Argentina
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}