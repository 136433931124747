import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import { HashLink } from 'react-router-hash-link';



function Menu(props) {
    return (
        <Box sx={{ display: 'flex' }}>
            <Grid container direction="row" spacing={1}>
                <Grid item>
                    <Typography>
                        Ir a:
                    </Typography>
                </Grid>
                <Grid item>
                    <HashLink smooth to={'/#turbine'}>
                        Productos
                    </HashLink>
                </Grid>
                <Grid item>
                    <HashLink smooth to="/#solutions">
                        <Typography>
                            Soluciones
                        </Typography>
                    </HashLink>
                </Grid>
                <Grid item>
                    <HashLink smooth to="/#turbine">
                        <Typography>
                            Transmisión
                        </Typography>
                    </HashLink>
                </Grid>
                <Grid item>
                    <HashLink smooth to="/#presentations">
                        <Typography>
                            Información de la Compañia
                        </Typography>
                    </HashLink>
                </Grid>
                <Grid item>
                    <HashLink smooth to="/#office">
                        <Typography>
                            Nueva Oficina Neuquén
                        </Typography>
                    </HashLink>
                </Grid>
                <Grid item>
                    <HashLink smooth to="/#end">
                        <Typography>
                            Conoce al Equipo
                        </Typography>
                    </HashLink>
                </Grid>
            </Grid>
        </Box>


    )
}

export default Menu;
