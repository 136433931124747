import React, { useState, useEffect } from 'react';
import AnnouncementCard from './announcementCard';
import axios from "axios";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RefreshIcon from '@mui/icons-material/Refresh';
import NewAnnouncement from "./newAnnouncement";

export default function Announcements(props) {
    const [announcements, setAnnouncements] = useState(null);
    const [refresh, setRefresh] = useState(false);


    let scroll;

    if (props.admin) {
        scroll = { maxHeight: 500, overflow: 'auto' }
    }
    else {
        scroll = { maxHeight: 500, overflow: 'auto' }
    }

    function getAnnouncements() {
        axios.get(`https://se-conference-app.herokuapp.com/announcements`)
            .then(function (response) {

                const data = response.data;

                const announceData = [];

                data.map((announcement) => {
                    const announcementToPush = {
                        id: announcement["_id"],
                        title: announcement.title,
                        desc: announcement.desc
                    }

                    announceData.push(announcementToPush);
                })
                setAnnouncements(announceData);
            })
            // Handle an error response from the server (we had issuses creating our preset)
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {

        setAnnouncements(null);
        getAnnouncements();
        setRefresh(false);
    }, [refresh])

    return (
        <Box content={"section"} spacing={2} >
            {props.admin ? <NewAnnouncement setRefresh={setRefresh} /> : null}
            <Container>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    justify="center"
                    spacing={2}

                >
                    <Grid item>
                        <Typography align={"center"} variant="h5">
                            Anuncios
                        </Typography>
                    </Grid>
                    <Card spacing={2} sx={{ width: "100%", bgcolor: 'primary.dark' }}>
                        <CardContent>
                            <Box sx={{ minHeight: "330px", maxHeight: "300px" }}>
                                <Grid container justifyContent="flex-end" >
                                    <Grid item>
                                        <Button variant="contained" sx={{ mb: 3 }} onClick={() => { setRefresh(true) }} endIcon={<RefreshIcon />}>
                                            Actualizar
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Container spacing={2} sx={{ scroll, minHeight: "330px", mb: 3, maxHeight: "330px", overflow: "auto" }}>
                                    {
                                        announcements ? announcements.map((announcement, idx) => {
                                            return (
                                                <Grid item sx={{ mb: 1 }}>
                                                    <AnnouncementCard setRefresh={setRefresh} admin={props.admin} id={announcement.id} title={announcement.title} desc={announcement.desc} />
                                                </Grid>
                                            )
                                        }) :
                                            <Grid item>
                                                <Typography> There are no announcements at this time.  </Typography>
                                            </Grid>
                                    }
                                </Container>
                            </Box>
                        </CardContent>
                    </Card>


                </Grid>
            </Container>
        </Box>

    );
}