import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';

import VideoModal from './VideoModal.js';
import CorIMG from '../../../images/Corporate/company.png';
import EngRepIMG from '../../../images/Corporate/energyreport.png';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const contents = [
    {
        title: `Presentación de la compañía`,
        image: CorIMG,
        pdfLink: "/pdfs/Corporate/Company-Presentation.pdf",
        imageLink: "https://se-conference-app.herokuapp.com/static/media/sgt100.721fa4fa31390562e656.jpg",
        videoLink: "https://sepatagonia.ddns.net/videos/SEPurpose.mp4",
        button: "Presentación de la compañía",
        description: "Apoyamos a nuestros clientes en sus viajes individuales hacia la descarbonización. Impulsamos la transición hacia un mundo energético más sostenible. Nuestros productos, soluciones y servicios cubren casi toda la cadena de valor de la energía. Tenemos el know-how, las tecnologías innovadoras. Convertimos ideas en realidad."
    },
    {
        title: "Informe de Energía Renovable",
        image: EngRepIMG,
        pdfLink: "/pdfs/Corporate/LATAM-Clean-Energy-Report-ESP-JUL22.pdf",
        button: "Descargar Folleto",
        description: "Tomando medidas con foco en las prioridades clave para la transición energética, América Latina podría ganar influencia mundial en el suministro de energía"

    }
]




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const item = {
        display: 'flex',
        flexDirection: 'column',
        px: 5,
    };

    return (

        <Box component="section"
            width="100%"
            sx={{
                flexDirection: 'column',
                display: 'flex',
                overflow: 'hidden',
                mt: 5,
                mb: 5,
            }}
        >
            <Container sx={{ display: 'flex', position: 'relative' }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box >
                            <Typography variant="h5" sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                                Mas información acerca de Siemens Energy
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 2 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} color="white">
                            <Tabs value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="inherit"
                                indicatorColor="secondary"
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                xs={12}
                                sx={{ selected: { backgroundColor: "secondary" } }}
                            >

                                <Tab label="Presentación de la compañía" {...a11yProps(0)} />
                                <Tab label="Informe de Energía Renovable" {...a11yProps(1)} />

                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            contents.map((content, idx) => {
                                return (
                                    <TabPanel value={value} index={idx}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} lg={5} align="center"
                                            >

                                                <img
                                                    width={imgSize}
                                                    src={content.image}
                                                />

                                            </Grid>
                                            <Grid item xs={12} lg={7}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid item >
                                                        {content.title === "Presentación de la compañía" ?
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                                                Qué hacemos? <br /> Energizamos la sociedad
                                                            </Typography>
                                                            :
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                                                Informe de Energía Renovable
                                                            </Typography>
                                                        }

                                                        <br />
                                                        <Typography
                                                            sx={{ mb: 4, color: 'white' }}>
                                                            {content.description}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="row" justifyContent="space-around" spacing={2}>
                                                            <Grid item>
                                                                <Link rel="noopener noreferrer"
                                                                    target="_blank"
                                                                    href={content.pdfLink}
                                                                    underline="none"
                                                                    color="white"
                                                                >
                                                                    <Button variant="outlined" color="inherit" startIcon={<PDFIcon />} sx={{ borderRadius: 8 }}>{content.button}</Button>
                                                                </Link>
                                                            </Grid>
                                                            {
                                                                content.videoLink ?
                                                                    <Grid item>
                                                                        <VideoModal title={"Ver Video"} color={"white"} variant={"outlined"} link={content.videoLink} />
                                                                    </Grid>
                                                                    : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Container>
        </Box >


    );
}