import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';

import AltaTension from '../../../images/Transmission/altatension.png'
import FACTS from '../../../images/Transmission/facts.jpg'
import HVDC from '../../../images/Transmission/hvdc.png'
import SOM from '../../../images/Transmission/transmissionservice.png'

import PlaceHolder from '../../../images/placeholder.png';

import VideoModal from '../views/VideoModal';


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const solutions = [
    {
        button: "Descargar Folleto",
        button2: "Descargar Folleto",
        title: "Subestaciones de Alta Tensión",
        image: AltaTension,
        pdfLink: "/pdfs/Transmission/SubestAltaTension.pdf",
        pdfLink2: "/pdfs/Transmission/SubestAltaTensionBrochure.pdf",
 
        description: "Las subestaciones de alto voltaje, los puntos nodos de la compleja infraestructura de transmisión de energía actual, juegan un papel clave para satisfacer esta demanda de manera segura y confiable. Siemens Energy los suministra llave en mano."
    },
    {
        button: "Descargar Folleto ",
        title: "FACTS",
        image: FACTS,
        videoLink: "https://sepatagonia.ddns.net/videos/FACTS.mp4",
        description: "Los sistemas de transmisión flexibles de AC (FACTS) son la solución perfecta para aumentar la confiabilidad de las redes de AC, mejorar la calidad de la energía y aumentar la eficiencia de la transmisión."

    },
    {
        button: "Descargar Folleto HVDC Classic",
        button2: "Descargar Folleto HVDC PLus",
        title: "HVDC",
        image: HVDC,
        pdfLink: "/pdfs/Transmission/HVDCClassic.pdf",
        pdfLink2: "/pdfs/Transmission/HVDCPlus.pdf",
      
        description: "La tecnología HVDC ofrece los medios más eficientes para transmitir una gran cantidad de energía a largas distancias, ayudar a conectar energía verde a la red y estabilizar las redes trifásicas."

    },
    {
        button: "Descargar Folleto",
        title: "Servicios, Operación y Mantenimiento",
        image: SOM,
        pdfLink: "/pdfs/Transmission/ServiciosOperacionyMantenimiento.pdf",
        videoLink: "https://sepatagonia.ddns.net/videos/T-SVPortfolio.mp4",
        videoLink2: "https://sepatagonia.ddns.net/videos/T-SVDigitalization.mp4",
       
        description: "Apoyamos a los clientes industriales y de transmisión de energía durante todo el ciclo de vida de la cadena energética con nuestros servicios integrales de alto valor agregado. "

    },
    // {
    //     button: "Catálogo de turbinas de gas",
    //     title: "Ver Más",
    //     image: ViewMore,
    //     pdfLink: "/pdfs/Turbines/GasTurbines.pdf",
    //     link: "https://www.siemens-energy.com/mx/es/soluciones/servicios/transmision-de-energia.html",
    //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

    // },

]




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box component="section"
                width="100%"
                sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    overflow: 'hidden',
                    mt: 5,
                    mb: 5,
                }}
            >
                <Container sx={{ display: 'flex', position: 'relative' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} >
                            <Box>
                                <Typography variant="h5" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
                                    Soluciones
                                </Typography>
                                <Typography variant="subtitle1" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
                                    Siemens Energy proporciona servicios integrales relacionados con productos y de valor agregado para sistemas de energía.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 2 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} color="white">
                                <Tabs value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    xs={12}
                                    sx={{ selected: { backgroundColor: "secondary" } }}
                                >

                                    <Tab label="Subestaciones de Alta Tensión" {...a11yProps(0)} />
                                    <Tab label="FACTS" {...a11yProps(1)} />
                                    <Tab label="HVDC" {...a11yProps(2)} />
                                    <Tab label="Servicios, Operación y Mantenimiento" {...a11yProps(3)} />
                                    {/* <Tab label="Ver Más" {...a11yProps(4)} /> */}
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                solutions.map((solution, idx) => {
                                    return (
                                        <TabPanel value={value} index={idx}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item xs={12} lg={5} align="center">

                                                    <img
                                                        width={imgSize}
                                                        src={solution.image}
                                                    />

                                                </Grid>
                                                <Grid item xs={12} lg={7}>
                                                    <Grid container >
                                                        <Grid item >
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                                                {solution.title}
                                                            </Typography>
                                                            <br />
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{ mb: 4, color: 'white' }}>
                                                                {solution.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row"  spacing={2}>
                                                                {solution.pdfLink ?
                                                                    <Grid item>
                                                                        <Link rel="noopener noreferrer"
                                                                            target="_blank"
                                                                            href={solution.pdfLink}
                                                                            underline="none"
                                                                            color="white"
                                                                        >
                                                                            <Button variant="outlined" color="inherit" startIcon={<PDFIcon />} sx={{ borderRadius: 8 }}>{solution.button}</Button>
                                                                        </Link>
                                                                    </Grid>
                                                                    : null
                                                                }
                                                                {solution.pdfLink2 ?
                                                                    <Grid item>
                                                                        <Link rel="noopener noreferrer"
                                                                            target="_blank"
                                                                            href={solution.pdfLink2}
                                                                            underline="none"
                                                                            color="white"
                                                                        >
                                                                            <Button variant="outlined" color="inherit" startIcon={<PDFIcon />} sx={{ borderRadius: 8 }}>{solution.button2}</Button>
                                                                        </Link>
                                                                    </Grid>
                                                                    : null
                                                                }
                                                                {solution.link ?
                                                                    <Grid item>
                                                                        <Link rel="noopener noreferrer"
                                                                            target="_blank"
                                                                            href={solution.link}
                                                                            underline="none"
                                                                            color="white"
                                                                        >
                                                                            <Button variant="outlined" color="inherit" sx={{ borderRadius: 8 }}>Mas Información</Button>
                                                                        </Link>
                                                                    </Grid>
                                                                    : null
                                                                }
                                                                {solution.videoLink ?

                                                                    <Grid item>
                                                                        <VideoModal title={"Ver Video"} color={"white"} variant={"outlined"} link={solution.videoLink} />
                                                                    </Grid>

                                                                    : null
                                                                }
                                                                {solution.videoLink2 ?

                                                                    <Grid item>
                                                                        <VideoModal title={"Ver Video"} color={"white"} variant={"outlined"} link={solution.videoLink2} />
                                                                    </Grid>

                                                                    : null
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Box >
        </>
    );
}