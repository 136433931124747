import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>


            {
                props.variant === "outlined" ?
                    <Box color={props.color}>
                        <Button variant={props.variant} color="inherit" sx={{ borderRadius: 8 }} onClick={handleOpen}>{props.title}</Button>
                    </Box>
                    :
                    <Box color={props.color}>
                        <Button variant={props.variant} color={props.color} sx={{ borderRadius: 8 }} onClick={handleOpen}>{props.title}</Button>
                    </Box>

            }




            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item align="center">
                            <video width="400" height="225" controls>
                                <source src={props.link} type="video/mp4" />
                            </video>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
