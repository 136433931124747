import * as React from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import EditIcon from '@mui/icons-material/Edit';

export default function EditAnnouncement(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [title, setTitle] = React.useState(props.title);
    const [desc, setDesc] = React.useState(props.desc);

    function handleSubmit() {
        editAnnouncement(props.id);
        setOpen(false);
        props.setRefresh(true); 
    }

    function editAnnouncement(id) {
        axios.put(`https://se-conference-app.herokuapp.com/announcements/edit/${id}`, {
            title: title,
            desc: desc
        })
            .then(function (response) {
            })
            // Handle an error response from the server (we had issuses creating our preset)
            .catch(function (error) {
            });
    }

    return (
        <div>
            <IconButton aria-label="delete" onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Announcement</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Description text
                    </DialogContentText> */}
                    <Grid container direction="column">
                        <Grid item >
                            <TextField
                                defaultValue={title}
                                placeholder="Title"
                                style={{ minWidth: 400 }}
                                onChange={(e) => { setTitle(e.target.value) }}
                            />
                        </Grid>
                        <Grid item >
                            <TextField
                                defaultValue={desc}
                                placeholder="Description"
                                multiline
                                style={{ minWidth: 400 }}
                                onChange={(e) => { setDesc(e.target.value) }}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Edit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
