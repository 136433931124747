import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/appbar';
import BackToTop from './components/backtotop';
import { ThemeProvider } from '@mui/material';
import ReactGA from 'react-ga4';
import customTheme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Home from './onepirate/Home.js';
import Transmission from './onepirate/Transmission.js';
import Speakers from './pages/speakers';
import Agenda from './pages/agenda';
import Survey from './pages/survey';
import BoothSurvey from './pages/publicsurvey';
import Register from './pages/register';
import Verify from './pages/verify';
import Login from './pages/login';
import EventInfo from './pages/eventinfo';
import AdminPortal from './pages/adminportal';
import Announcements from './components/announcements';
import Contacts from './onepirate/modules/views/Contacts';
import AppFooter from './onepirate/modules/views/AppFooter';

import NewUnits from './onepirate/modules/views/TurbineTabs';
import Services from './onepirate/modules/views/Services.js';
import Solutions from './onepirate/modules/views/SolutionsTabs';
import ROC from './onepirate/modules/views/ROCInfo';
import AnyQuestions from './onepirate/modules/views/AnyQuestions'



ReactGA.initialize('G-C6673RSSZJ');
ReactGA.send("pageview");

function App() {

  // bug: must refresh the page to get the appbar to rerender 
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // to report page view
    ReactGA.pageview(window.location.pathname + window.location.search);

    const email = localStorage.getItem("loggedIn");
    if (email) {
      setLoggedIn(true);
    }
  }, [loggedIn])


  return (
    <>
      <Router>
        <ThemeProvider theme={customTheme}>
          <NavBar loggedIn={loggedIn} />
          <Box sx={{ height: "100%", mb: 2 }}>
            <Routes>

              {/* <Route path="/register" element={<Register />} /> */}
              {/* <Route path="/agenda" element={<Agenda />} /> */}

              {/* Invited Routes */}
              <Route path="/verify" element={<Login setLoggedIn={setLoggedIn} />} />
              <Route path="/evento" element={<EventInfo />} />
              <Route path="/verify/eventsurvey" element={<Survey surveyId={2} />} />
              <Route path="/verify/announcements" element={<Announcements admin={false} />} />


              {/* Admin Routes */}
              <Route path="/admin" element={<AdminPortal />} />


              {/* Public Routes */}
              <Route path="/services" element={<Services />} />
              <Route path="/newunits" element={<NewUnits />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/roc" element={<ROC />} />
              <Route path="/transmission" element={<Transmission />} />


              <Route path="/contacts" element={<Contacts />} />
              <Route path="/boothsurvey" element={<BoothSurvey surveyId={1} />} />

              <Route path="/" element={<Home loggedIn={loggedIn} />} />

            </Routes>
            <BackToTop />
          </Box>
          <AnyQuestions />
          <AppFooter />
        </ThemeProvider>
      </Router>

    </>
  );
}

export default App;