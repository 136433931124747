import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';

import Breadcrumbs from './Breadcrumbs';
import SGT100 from '../../../images/Turbine/sgt100.jpg';
import SGT400 from '../../../images/Turbine/sgt400.jpg';
import SGT750 from '../../../images/Turbine/sgt750.jpg';
import SGT800 from '../../../images/Turbine/sgt800.jpg';
import ViewMore from '../../../images/Turbine/ViewMore.png';

import VideoModal from './VideoModal.js';


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const turbineData = [
    {
        button: "Descargar Folleto SGT-100",
        title: "SGT-100",
        image: SGT100,
        pdfLink: "/pdfs/Turbines/SGT100.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-generation/gas-turbines/sgt-100.html",
        videoLink: "https://sepatagonia.ddns.net/videos/SGT100.mp4",
        description: "La turbina de gas industrial SGT-100 es una unidad comprobada para todas las aplicaciones de generación de energía eléctrica y accionamiento mecánico."
    },
    {
        button: "Descargar Folleto SGT-400",
        title: "SGT-400",
        image: SGT400,
        pdfLink: "/pdfs/Turbines/SGT400.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-generation/gas-turbines/sgt-400.html",
        videoLink: "https://sepatagonia.ddns.net/videos/SGT400.mp4",
        description: "La SGT-400 es una turbina de gas de doble eje simple y robusta, adecuada tanto para aplicaciones de generación de energía como de accionamiento mecánico."

    },
    {
        button: "Descargar Folleto SGT-750",
        title: "SGT-750",
        image: SGT750,
        pdfLink: "/pdfs/Turbines/SGT750.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-generation/gas-turbines/sgt-750.html",
        videoLink: "https://sepatagonia.ddns.net/videos/SGT750.mp4",
        description: "Con un tiempo de actividad maximizado, un rendimiento de primera clase y un bajo impacto ambiental, el SGT-750 le ofrece una alta rentabilidad de por vida. Es una opción perfecta para la industria del petróleo y el gas, así como para la generación de energía industrial."

    },
    {
        button: "Descargar Folleto SGT-800",
        title: "SGT-800",
        image: SGT800,
        pdfLink: "/pdfs/Turbines/SGT800.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-generation/gas-turbines/sgt-800.html",
        description: "Con un historial comprobado a largo plazo de instalaciones exitosas en todo el mundo, la turbina de gas SGT-800 es una excelente opción tanto para la generación de energía industrial como para aplicaciones de petróleo y gas."

    },
    {
        button: "Catálogo de turbinas de gas",
        title: "Ver Más",
        image: ViewMore,
        pdfLink: "/pdfs/Turbines/GasTurbines.pdf",
        link: "https://www.siemens-energy.com/global/en/offerings/power-generation/gas-turbines.html",
        description: " Siemens Energy ofrece turbinas de gas para todas sus necesidades. Consulte nuestro catálogo para ver la línea completa."

    },

]




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ mb: 5 }}>
                <Container sx={{ display: 'flex', position: 'relative' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} >
                            <Box>
                                <Breadcrumbs title={"Nuevas Unidades"} />
                                <Typography variant="h5" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
                                    Nuevas Unidades
                                </Typography>
                                <Typography variant="h5" sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                                    Turbinas de gas para aplicaciones de petróleo y gas
                                </Typography>
                                <Typography
                                    align="flex-start"
                                    variant="subtitle1"
                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                                >
                                    Para la generación de energía en aplicaciones upstream, midstream y downstream, así como accionamientos mecánicos para compresores y bombas, las turbinas de gas de Siemens Energy son probadas y confiables en la industria.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 2 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} color="white">
                                <Tabs value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    xs={12}
                                    sx={{ selected: { backgroundColor: "secondary" } }}
                                >

                                    <Tab label="SGT-100" {...a11yProps(0)} />
                                    <Tab label="SGT-400" {...a11yProps(1)} />
                                    <Tab label="SGT-750" {...a11yProps(2)} />
                                    <Tab label="SGT-800" {...a11yProps(3)} />
                                    <Tab label="Ver Más" {...a11yProps(4)} />
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                turbineData.map((turbine, idx) => {
                                    return (
                                        <TabPanel value={value} index={idx}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={5} align="center">
                                                    <Link href={turbine.image} target="_blank">
                                                        <img
                                                            width={imgSize}
                                                            src={turbine.image}
                                                        />
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={12} lg={7}>
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item >
                                                            <Typography
                                                                variant="h6"
                                                                sx={{ mb: 4, mt: { xs: 3, sm: 0 }, color: 'white' }}>
                                                                {turbine.title}
                                                            </Typography>
                                                            <br />
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{ mb: 4, color: 'white' }}>
                                                                {turbine.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction="row" justifyContent="space-around" spacing={2}>
                                                                <Grid item>
                                                                    <Link rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        href={turbine.pdfLink}
                                                                        underline="none"
                                                                        color="white"
                                                                    >
                                                                        <Button variant="outlined" color="inherit" startIcon={<PDFIcon />} sx={{ borderRadius: 8 }}>{turbine.button}</Button>
                                                                    </Link>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Link rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        href={turbine.link}
                                                                        underline="none"
                                                                        color="white"
                                                                    >
                                                                        <Button variant="outlined" color="inherit" sx={{ borderRadius: 8 }}>Mas Información</Button>
                                                                    </Link>
                                                                </Grid>
                                                                {turbine.videoLink ?

                                                                    <Grid item>
                                                                        <VideoModal title={"Ver Video"} color={"white"} variant={"outlined"} link={turbine.videoLink} />
                                                                    </Grid>

                                                                    : null
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                </Container>
                <Container sx={{ display: 'flex', position: 'relative' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} >
                            <Box>
                                <Typography variant="h5" sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                                    Compresores
                                </Typography>
                                <Typography
                                    align="flex-start"
                                    variant="subtitle1"
                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                                >
                                    Siemens Energy ofrece una cartera completa de soluciones de compresión turbo y recíproca para la industria del petróleo y gas y otras industrias de procesos.
                                </Typography>
                            </Box>
                            <Grid container direction="row" spacing={2}>
                                <Grid item>
                                    <Link rel="noopener noreferrer"
                                        target="_blank"
                                        href={"/pdfs/Compressors/CO2CompressionPresentation.pdf"}
                                        underline="none"
                                    >
                                        <Button variant="contained"
                                            color="primary"

                                            startIcon={<PDFIcon />}
                                            sx={{
                                                borderRadius: 8,

                                            }}
                                        >
                                            Compresores de CO2
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link rel="noopener noreferrer"
                                        target="_blank"
                                        href={"/pdfs/Compressors/RecipCompressorsPortfolioOverview.pdf"}
                                        underline="none"
                                    >
                                        <Button variant="contained"
                                            color="primary"

                                            startIcon={<PDFIcon />}
                                            sx={{
                                                borderRadius: 8,

                                            }}
                                        >
                                            Siemens Energy Portafolio
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link rel="noopener noreferrer"
                                        target="_blank"
                                        href={"/pdfs/Compressors/RecipCompressorsHydrogenApplications.pdf"}
                                        underline="none"
                                    >
                                        <Button variant="contained"
                                            color="primary"

                                            startIcon={<PDFIcon />}
                                            sx={{
                                                borderRadius: 8,

                                            }}
                                        >
                                            Aplicaciones de Hidrógeno
                                        </Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link rel="noopener noreferrer"
                                        target="_blank"
                                        href={"https://www.siemens-energy.com/mx/es/soluciones/compresores-y-turbinas-de-expansion.html"}
                                        underline="none"
                                    >
                                        <Button variant="contained"
                                            color="primary"

                                            startIcon={<PDFIcon />}
                                            sx={{
                                                borderRadius: 8,

                                            }}
                                        >
                                            Mas Información
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}