import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

// MUI Components
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

// MUI Styling
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';



const MOBILEBOX = 350;
const DESKTOPBOX = 850;
let boxSize = DESKTOPBOX;

export default function Survey(props) {

    const [surveys, setSurveys] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [msg, setMsg] = useState("Thank you for your submission.");
    const navigate = useNavigate();
    // for submit confirmation
    const [open, setOpen] = React.useState(false);

    let isMobileView = useMediaQuery(theme => theme.breakpoints.only("xs"));

    if (isMobileView) {
        boxSize = MOBILEBOX;
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getSurvey(props.surveyId);
    }, [])


    useEffect(() => {
        const email = localStorage.getItem("email");
        if (!email) {
            navigate("/welcome");
        }
    }, [])


    function getSurvey(id) {

        axios.get(`https://se-conference-app.herokuapp.com/survey/?id=${id}`)
            .then(function (response) {

                const surveyData = response.data;
                const defaultAnswers = []
                setSurveys(surveyData);

                surveyData.map((question) => {

                    const answer = {
                        question: question.question_id,
                        answer: '',
                        question_desc: question.question_desc,
                        answer_type: question.answer_type

                    }

                    defaultAnswers.push(answer);
                })
                setAnswers(defaultAnswers);
            })
            // Handle an error response from the server (we had issuses creating our preset)
            .catch(function (error) {
                console.log(error);
            });

    }


    // onChange={(e) => { handleAnswerChange(e, idx) }}
    function handleAnswerChange(event, idx) {

        const newAnswers = [...answers];
        newAnswers[idx].answer = event.target.value;
        setAnswers(newAnswers);
        console.log("UPDATED" + JSON.stringify(answers));
    }

    
    function handleSubmit() {
        const email = localStorage.getItem("email");

        axios.post(`https://se-conference-app.herokuapp.com/results/create`, {
            email: email,
            surveyId: props.surveyId,
            answers: answers
        })
            .then(function (response) {
                setSubmitted(true);
            })
            // Handle an error response from the server (we had issuses creating our preset)
            .catch(function (error) {
                setMsg("You have already submitted this form");
                setSubmitted(true);
            });
    }


    return (
        <>
            {
                submitted ?
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }
                        }
                    >
                        <Typography> {msg} </Typography>
                        <Button
                            onClick={() => { navigate("/welcome"); }}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Go Back to Home
                        </Button>

                    </Box> :
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }
                        }
                    >
                        {
                            surveys.map((question, idx) => {
                                return (
                                    <Grid container spacing={2}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        p={2}>
                                        <Grid item xs={12}>

                                            <Card
                                                sx={{ minWidth: boxSize, maxWidth: boxSize }}>
                                                <CardContent>
                                                    <Typography> Question {question.question_id}: {question.question_desc} </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    {
                                                        (question.answer_type === "rank") ?

                                                            <FormControl>
                                                                <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                    onChange={(e) => { handleAnswerChange(e, idx) }}


                                                                >
                                                                    <FormControlLabel value={1} control={<Radio />} label="1" labelPlacement="bottom" />
                                                                    <FormControlLabel value={2} control={<Radio />} label="2" labelPlacement="bottom" />
                                                                    <FormControlLabel value={3} control={<Radio />} label="3" labelPlacement="bottom" />
                                                                    <FormControlLabel value={4} control={<Radio />} label="4" labelPlacement="bottom" />
                                                                    <FormControlLabel value={5} control={<Radio />} label="5" labelPlacement="bottom" />
                                                                    <FormControlLabel value={6} control={<Radio />} label="6" labelPlacement="bottom" />
                                                                    <FormControlLabel value={7} control={<Radio />} label="7" labelPlacement="bottom" />
                                                                    <FormControlLabel value={8} control={<Radio />} label="8" labelPlacement="bottom" />
                                                                    <FormControlLabel value={9} control={<Radio />} label="9" labelPlacement="bottom" />
                                                                    <FormControlLabel value={10} control={<Radio />} label="10" labelPlacement="bottom" />

                                                                </RadioGroup>
                                                            </FormControl>

                                                            : <TextField
                                                                placeholder="Please enter feedback here"
                                                                multiline
                                                                style={{ width: 800 }}
                                                                onChange={(e) => { handleAnswerChange(e, idx) }}
                                                            />
                                                    }
                                                </CardActions>
                                            </Card>
                                        </Grid>

                                    </Grid>
                                )
                            })
                        }
                        <Button
                            onClick={handleClickOpen}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Submit?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Please confirm that you wish to submit this survey.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={handleSubmit} autoFocus>
                                    Go ahead
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box >
            }
        </>
    );
}