import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import Breadcrumbs from './Breadcrumbs';


import Grace from '../../../images/Contacts/grace.jpg';
import Hector from '../../../images/Contacts/hector.jpg';
import Claudio from '../../../images/Contacts/claudio.jpg';
import FernandoC from '../../../images/Contacts/fernandoc.jpg';
import FernandoG from '../../../images/Contacts/fernandog.jpg';
import Gustavo from '../../../images/Contacts/gustavo.jpg';
import JuanPablo from '../../../images/Contacts/juanpablo.png';
import Leandro from '../../../images/Contacts/leandro.jpg';
import Melanie from '../../../images/Contacts/melanie.jpg';
import Pablo from '../../../images/Contacts/pablojavier.png';
import Sergio from '../../../images/Contacts/sergio.png';
import Daniel from '../../../images/Contacts/daniel.jpg';
import HernanPablo from '../../../images/Contacts/HernanPablo.jpg';
import Ivan from '../../../images/Contacts/ivan.jpg';

const services = [
  {
    name: "Fernando Caffarello",
    phone: "+54 911 3827 2845",
    email: "fernando.caffarello@siemens-energy.com",
    position: "Gerente de Desarrollo de Negocios Petróleo y Gas Siemens Energy",
    image: FernandoC,
    pdfLink: "/pdfs/Contacts/FernandoCaffarello.pdf",
    linkedIn: "https://ar.linkedin.com/in/fernando-alberto-caffarello-0314922b/en"

  },
  {
    name: "Grace Alvarez",
    phone: "+1 (407) 408-0291",
    email: "grace.alvarez@siemens-energy.com",
    position: "Directora de Estrategia, Desarrollo de Negocio y Calidad Región Latinoamérica",
    image: Grace,
    pdfLink: "/pdfs/Contacts/GraceAlvarez.pdf",
    linkedIn: "https://www.linkedin.com/in/e-graciela-grace-alvarez-mba-8149bab"

  },
  {
    name: "Héctor Bertuzzi",
    phone: "+54 911 5004 8235",
    email: "hector.bertuzzi@siemens-energy.com",
    position: "Head de Servicios para Generación y Aplicaciones Industriales - Argentina ",
    image: Hector,
    pdfLink: "/pdfs/Contacts/HectorBertuzzi.pdf",
    linkedIn: "https://ar.linkedin.com/in/hector-bertuzzi-46b99213"

  },
  {
    name: "Melanie Gnecco",
    phone: "+1 (407) 780-4775",
    email: "melanie.gnecco@siemens-energy.com",
    position: "Gerente Estrategia & Desarrollo de Negocio Servicios para la Región de Latinoamérica",
    image: Melanie,
    pdfLink: "/pdfs/Contacts/MelanieGnecco.pdf",
    linkedIn: "https://www.linkedin.com/in/melaniegnecco"
  },
  {
    name: "Sergio Meloni",
    phone: "+54 911 3294 4404",
    email: "sergio.meloni@siemens-energy.com",
    position: "Head Financiero de Servicios para Generación y Aplicaciones Industriales",
    image: Sergio,
    pdfLink: "/pdfs/Contacts/SergioMeloni.pdf",
    linkedIn: "https://ar.linkedin.com/in/sergio-antonio-meloni-20b948a"

  },


]



const units = [

  {
    name: "Fernando Gutierrez",
    phone: "+54 (11) 3821 3354",
    email: "fernando.gutierrez@siemens-energy.com",
    position: "Ventas Aplicaciones Industriales Siemens Energy",
    image: FernandoG,
    pdfLink: "/pdfs/Contacts/FernandoGutierrez.pdf",
    linkedIn: "https://ar.linkedin.com/in/fernando-gutierrez-056b496/es"
  },
  {
    name: "Juan Pablo Gomez Lamarque",
    phone: "+54 (11) 5432 6023 ",
    email: "juanpablo.gomez@siemens-energy.com",
    position: "Líder Aplicaciones Industriales Siemens Energy ",
    image: JuanPablo,
    pdfLink: "/pdfs/Contacts/JuanPabloGomezLamarque.pdf",
    linkedIn: "https://ar.linkedin.com/in/jpgomezlamarque"

  },

]

const transmission = [
  {
    name: "Pablo Javier Virginillo",
    phone: "+54 911 6644 5016",
    email: "pablo.virginillo@siemens-energy.com",
    position: "Ingeniero de Ventas y Desarrollo de Negocios Transmisión",
    image: Pablo,
    pdfLink: "/pdfs/Contacts/PabloJavierVirginillo.pdf",
    linkedIn: "https://ar.linkedin.com/in/pablo-javier-virginillo-40a03126"

  },
  {
    name: "Leandro Vattimo",
    phone: "+54 9 11 5727 0882",
    email: "leandro.vattimo@siemens-energy.com",
    position: "Líder de la División de País",
    image: Leandro,
    pdfLink: "/pdfs/Contacts/LeandroVattimo.pdf",
    linkedIn: "https://ar.linkedin.com/in/leandro-ariel-vattimo-09235839"

  },

  {
    name: "Hernán Pablo Bonamino",
    phone: "+54 9 11 3261 3302",
    email: "pablo.bonamino@siemens-energy.com",
    position: "Consultor de Ventas - Transmisión",
    image: HernanPablo,
    pdfLink: "/pdfs/Contacts/PabloBonamino.pdf",
    linkedIn: "https://www.linkedin.com/in/hernan-pablo-bonamino-a0061b9/"

  },

  {
    name: "Daniel Gonzalez",
    phone: " +54 9 11 5401 4516",
    email: "daniel.gonzalez@siemens-energy.com",
    position: "Profesional de Soporte de Ventas",
    image: Daniel,
    pdfLink: "/pdfs/Contacts/DanielGonzalez.pdf",
    linkedIn: "https://ar.linkedin.com/in/daniel-gonzalez-ab87405"
  },

]

const solutions = [
  {
    name: "Claudio Ginobili",
    phone: "+54 911 5009 6760",
    email: "claudio.ginobili@siemens-energy.com",
    position: "Head Argentina para Unidad de Negocio - Soluciones de Procesos",
    image: Claudio,
    pdfLink: "/pdfs/Contacts/ClaudioGinobili.pdf",
    linkedIn: "https://ar.linkedin.com/in/claudio-ginobili-784b25"

  },
  {
    name: "Gustavo Solier",
    phone: "+54 911 3374 9373",
    email: "gustavo.solier@siemens-energy.com",
    position: "Soluciones de Procesos Gerente de Cuentas Petróleo y Gas",
    image: Gustavo,
    pdfLink: "/pdfs/Contacts/GustavoSolier.pdf",
    linkedIn: "https://ar.linkedin.com/in/gustavosolier"

  },

  {
    name: "Ivan Melnikov",
    phone: " +54 (11) 6692 3509",
    email: "ivan.melnikov@siemens-energy.com",
    position: "Ingeniero de Soluciones",
    image: Ivan,
    pdfLink: "/pdfs/Contacts/IvanMelnikov.pdf",
    linkedIn: "https://ar.linkedin.com/in/ivan-melnikov-2b1b2314"
  },

]


function Section(contacts, title) {

  return (
    <Grid container spacing={4} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ml:{xs:3, md:0}}}>
          {title}
        </Typography>
      </Grid>
      {contacts.map((contact) => (
        <Grid item key={contact.email} xs={12} sm={6} md={4}>
          <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <CardMedia
              component="img"
              height="350"
              image={contact.image}
              alt={`${contact.name} image`}
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography gutterBottom variant="h5" component="h2">
                {contact.name}
              </Typography>
              <Typography variant="body1">
                {contact.position} <br /> <br />
              </Typography>

              <Grid container direction="column" >
                <Grid item>
                  <Grid container direction="row" >
                    <PhoneIcon />
                    <Typography variant="body1">
                      {contact.phone} <br />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <EmailIcon />
                    <Typography variant="body1">
                      {contact.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container /*justifyContent="space-between"*/>
                <Grid item>
                  <Link rel="noopener noreferrer"
                    target="_blank"
                    href={contact.pdfLink}
                    underline="none"
                  >
                    <Button>Ver Más</Button>
                  </Link>
                </Grid>
                <Grid item>
                  <Link rel="noopener noreferrer"
                    target="_blank"
                    href={contact.linkedIn}
                    underline="none"
                  >
                    <Button>LinkedIn</Button>
                  </Link>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}




export default function Album() {
  return (
    <Container sx={{ display: 'flex', position: 'relative' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs title={"Contactos"} />
        </Grid>
        <Grid item>
          <Box>
            <Typography variant="h5" sx={{ mb: 4, color: '#1B1534' }}>
              ¿Cómo podemos ayudarle?
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4, mt: 3, color: '#1B1534' }}>
              Si tiene preguntas, comentarios o sugerencias, comuníquese con nosotros.
            </Typography>
          </Box>
        </Grid>
        {Section(services, "Servicios")}
        {Section(units, "Nuevas Unidades")}
        {Section(transmission, "Transmisión")}
        {Section(solutions, "Soluciones")}
      </Grid>
    </Container>
  );
}

