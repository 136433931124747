import * as React from 'react';
import { useMemo } from "react";
import Box from '@mui/material/Box';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function Home() {
    const [width, setWidth] = React.useState("300");
    const [height, setHeight] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));



    function Map() {
        return (
            <Box align={"center"}>
                <GoogleMap
                    zoom={10}
                    center={{ lat: -38.948928, lng: -68.0604748 }}
                    mapContainerStyle={{
                        width: width,
                        height: height
                    }}
                >
                    <Marker position={{ lat: -38.948928, lng: -68.0604748 }} />
                </GoogleMap>
            </Box>
        )
    }


    React.useEffect(() => {
        if (isMobile) {
            setWidth("70vw");
            setHeight("50vh");
        }
        else if (isDesktop) {
            setWidth("30vw");
            setHeight("50vh");
        }
        else {
            setWidth("70vw");
            setHeight("50vh");
        }

    }, [isMobile, isDesktop])



    const render = (status) => {
        return <h1>{status}</h1>;
    };

    return (
        <Wrapper apiKey={"AIzaSyBmUmVPHqVLZpzbMOKuYhKkIJKGdEy2z8M"} render={render} >
            <Map />
        </Wrapper>
    )
}
