import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import DeleteAnnouncement from './deleteAnnouncement';
import EditAnnouncement from './editAnnouncement';
import Grid from '@mui/material/Grid';

export default function AnnouncementCard(props) {
  return (
    <Card sx={{ minWidth: 275, mb:4 }} xs={6} variant="outlined">
      <CardContent>
        <Grid container direction="row" alignItems="center"  justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" component="div">
              {props.title} 
            </Typography>
            <Typography variant="body2">
              {props.desc} 
            </Typography>
          </Grid>
          <Grid item>
            {props.admin ?
              <CardActions>
                <EditAnnouncement setRefresh={props.setRefresh} id={props.id} title={props.title} desc={props.desc} />
                <DeleteAnnouncement setRefresh={props.setRefresh} id={props.id} title={props.title} desc={props.desc} />
              </CardActions>
              : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}