import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import PDFIcon from '@mui/icons-material/PictureAsPdf';

// Images
import RTPO from '../../../images/Solutions/RTPO- Keyvisual.png'
import SIWELL from '../../../images/Solutions/siwell.png'
import SLDS from '../../../images/Solutions/slds.png'
import APM from '../../../images/Solutions/APM.png'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import VideoModal from './VideoModal.js';
import Breadcrumbs from './Breadcrumbs';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const [imgSize, setImgSize] = React.useState("300");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    React.useEffect(() => {
        if (isMobile) {
            setImgSize("300")
        }
        else if (isDesktop) {
            setImgSize("450")
        }
        else
            setImgSize("500")
    }, [isMobile, isDesktop])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ mb: 5 }}>
                <Container sx={{ display: 'flex', position: 'relative' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Box >
                                <Breadcrumbs title={"Soluciones"} />
                                <Typography variant="h5" sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5, color: '#1B1534' } }}>
                                    Soluciones
                                </Typography>
                                <Typography
                                    align="flex-start"
                                    variant="subtitle1"
                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'text.main' }}
                                >
                                    En la era de transformación digital del ‘Internet de las cosas’ (IoT), la industria del petróleo y el gas está buscando soluciones inteligentes para impulsar el rendimiento, mejorar la eficiencia y, en última instancia, reducir los costos. Siemens Energy ayuda a los clientes a optimizar el rendimiento de los activos y equipos críticos de petróleo y gas. Nos basamos en una amplia cartera de software, una profunda experiencia en análisis de datos y una amplia experiencia en petróleo y gas y otras industrias.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container sx={{ display: 'flex', position: 'relative', bgcolor: '#1B1534', p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} color="white">
                                <Tabs value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    textColor="inherit"
                                    indicatorColor="secondary"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    xs={12}
                                    sx={{ selected: { backgroundColor: "secondary" } }}
                                >

                                    <Tab label="RTPO" {...a11yProps(0)} />
                                    <Tab label="SIWELL" {...a11yProps(1)} />
                                    <Tab label="SLDS" {...a11yProps(2)} />
                                    <Tab label="APM para Oil & Gas" {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={value} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={5} align="center">
                                        <img
                                            width={imgSize}
                                            src={RTPO}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item >
                                                <Typography
                                                    variant="h6"
                                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'white' }}>
                                                    Real Time Production Optimization (RTPO)
                                                </Typography>
                                                <br />
                                                <Typography
                                                    sx={{ mb: 4, color: 'white' }}>
                                                    Las mejoras típicas en la producción de yacimientos petrolíferos están en el rango de 3-8%. Además, RTPO se ejecuta rápido. Esto permite volver a calcular rápidamente los puntos de ajuste del campo petrolífero para maximizar la producción en caso de que se produzcan averías en el equipo de campo.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/RTPOStandardPresentation.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto</Button>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/RTPOTechBrochure.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Folleto Técnico</Button>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/RTPOProductLeaf.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Product Leaf</Button>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={5} align="center">
                                        <img
                                            width={imgSize}
                                            src={SIWELL}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item >
                                                <Typography
                                                    variant="h6"
                                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'white' }}>
                                                    SIWELL
                                                </Typography>
                                                <br />
                                                <Typography
                                                    sx={{ mb: 4, color: 'white' }}>
                                                    SIWELL Artificial Lift Suite de Siemens Energy ofrece sistemas de automatización de pozos de próxima generación con inteligencia artificial, dispositivos inteligentes, computación en la nube e IoT.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/SiWellBrochure.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto</Button>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/SiWellOverview.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Overview</Button>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/SiWellOnepager.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Onepager</Button>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={5} align="center">
                                        <img
                                            width={imgSize}
                                            src={SLDS}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item >
                                                <Typography
                                                    variant="h6"
                                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'white' }}>
                                                    Spontaneous Leak Detection Service (SLDS)
                                                </Typography>
                                                <br />
                                                <Typography
                                                    sx={{ mb: 4, color: 'white' }}>
                                                    Un servicio de suscripción proporcionado por Siemens Energy para la detección de fugas espontáneas en tuberías, sistemas de recolección y elevadores marinos. El servicio se basa en la arquitectura IoT de Siemens Energy y aprovecha los algoritmos de procesamiento de datos avanzados de nuestro socio ProFlex para identificar y localizar pequeñas fugas en tiempo real.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justifyContent="space-around" spacing={2}>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/SLDPresentation.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Presentación </Button>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="/pdfs/Solutions/SLDFlyer.pdf"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" startIcon={<PDFIcon />} color="inherit" sx={{ borderRadius: 8 }}>Descargar Folleto</Button>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={5} align="center">
                                        <img
                                            width={imgSize}
                                            src={APM}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item >
                                                <Typography
                                                    variant="h6"
                                                    sx={{ mb: 4, mt: { xs: 5, sx: 4, sm: 5 }, color: 'white' }}>
                                                    APM para Oil & Gas
                                                </Typography>
                                                <br />
                                                <Typography
                                                    sx={{ mb: 4, color: 'white' }}>
                                                    APM es una transición habilitada por herramientas hacia el mantenimiento centrado en la confiabilidad y las inspecciones basadas en el riesgo.
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" justifyContent="space-around" spacing={2}>
                                                    <Grid item>
                                                        <Link rel="noopener noreferrer"
                                                            target="_blank"
                                                            href="https://www.siemens-energy.com/global/en/offerings/services/digital-services/apm-powerplants.html"
                                                            underline="none"
                                                            color="white"
                                                        >
                                                            <Button variant="outlined" color="inherit" sx={{ borderRadius: 8 }}>Mas Información</Button>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item>
                                                        <VideoModal title={"Ver Video"} color={"white"} variant={"outlined"} link={"https://sepatagonia.ddns.net/videos/APM4OG.mp4"} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}