import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

// let routes = [{name:'Home', route: '/welcome'},  
// {name:'Agenda', route: '/agenda'},  
// {name:'Speakers', route: '/speakers'},
// {name:'Event Info', route: '/eventinfo'},
// {name:'Survey', route: '/surveys'}]

let routes;
// pre and post survey dont change when you move from one to another aaaaaa
export default function Sidebar(props) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const navigate = useNavigate();


    if (props.loggedIn) {

        routes = [{
            name: 'Inicio',
            route: '/'
        },
        {
            name: 'Evento Exclusivo',
            route: '/evento'
        },
        {
            name: 'Servicios',
            route: '/services'
        },
        {
            name: 'Operación Remota y Consultoría',
            route: '/roc'
        },
        {
            name: 'Nuevas Unidades',
            route: '/newunits'
        },
        {
            name: 'Transmisión',
            route: '/transmission'
        },
        {
            name: 'Soluciones',
            route: '/solutions'
        },
        {
            name: 'Contactos',
            route: '/contacts'
        },
        {
            name: 'Encuesta',
            route: '/boothsurvey'
        },]
    }
    else {
        routes = [{
            name: 'Inicio',
            route: '/'
        },
        {
            name: 'Servicios',
            route: '/services'
        },
        {
            name: 'Operación Remota y Consultoría',
            route: '/roc'
        },
        {
            name: 'Nuevas Unidades',
            route: '/newunits'
        },
        {
            name: 'Transmisión',
            route: '/transmission'
        },
        {
            name: 'Soluciones',
            route: '/solutions'
        },
        {
            name: 'Contactos',
            route: '/contacts'
        },
        {
            name: 'Encuesta',
            route: '/boothsurvey'
        },]
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {routes.map((page) => (
                    <ListItem button key={page.name}
                        onClick={() => {

                            navigate(page.route)
                        }}>
                        <ListItemText primary={page.name} />
                    </ListItem>

                ))}

                {/* <HashLink smooth to={'/#turbine'}>
                    <ListItem button>
                        <ListItemText primary={"Hash test"} />
                    </ListItem>

                </HashLink>
                <HashLink smooth to={'/#office'}>
                    <ListItem button>
                        <ListItemText primary={"Hash test"} />
                    </ListItem>

                </HashLink> */}
            </List>
        </Box>
    );

    return (
        <div>
            <React.Fragment key={'right'}>
                <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={toggleDrawer('right', true)}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    {list('left')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}